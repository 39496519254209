"use strict";

document.addEventListener("DOMContentLoaded", function(){

const body = document.querySelector('body');
const submitDOB = document.querySelector('#submit-dob');
const dobError = document.querySelector('.dob-error');

function createCookie(name,value,days) {

  if (days) {
    var date = new Date();
    date.setTime(date.getTime()+(days*24*60*60*1000)); 
    var expires = "; expires="+date.toGMTString(); 
  } else {
    var expires = "";
  }
  
  document.cookie = name+"="+value+expires+"; path=/"; 
}

function isEighteenOrOlder(day, month, year) {
  // Parse input values as integers
  const inputDay = parseInt(day, 10);
  const inputMonth = parseInt(month, 10);
  const inputYear = parseInt(year, 10);

  // Create a Date object using the input values (months are zero-based in JavaScript)
  const inputDate = new Date(inputYear, inputMonth - 1, inputDay);

  // Calculate the current date
  const currentDate = new Date();

  // Calculate the date 18 years ago
  const eighteenYearsAgo = new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate());

  // Compare the input date with the date 18 years ago
  return inputDate <= eighteenYearsAgo;
}

if(submitDOB) {

  body.classList.add('no-scroll');

  submitDOB.addEventListener('click', function(e) {
    e.preventDefault();

    let dobDay = document.querySelector('input[name="dob-day"]').value;
    let dobMonth = document.querySelector('input[name="dob-month"]').value;
    let dobYear = document.querySelector('input[name="dob-year"]').value;

    if(isEighteenOrOlder( dobDay, dobMonth, dobYear )) {
      createCookie('ageVerified', true, 30);
      document.querySelector('.age-popup-outer').style.display="none";
      body.classList.remove('no-scroll');
    } else {
      dobError.classList.add('is-visible');
    }

  });

}



// NAVIGATION

const mobileNavToggle = document.querySelector('.toggle-mobile-nav');
const mobileNav = document.querySelector('.mobile-nav');

mobileNavToggle.addEventListener('click', function() {
  this.classList.toggle('is-active');
  mobileNav.classList.toggle('is-open');
});

const filters = document.querySelector('.filters-outer');
const sort = document.querySelector('.sort');
const openFilters = document.querySelector('.open-filters');
const closeFilters = document.querySelectorAll('.close-filters');
const toggleSort = document.querySelector('.toggle-sort');

if(filters) {

  openFilters.addEventListener('click', function() {
    filters.classList.add('is-open');
    body.classList.add('no-scroll');
  });

  closeFilters.forEach(function(el) {

    el.addEventListener('click', function() {
      filters.classList.remove('is-open');
      body.classList.remove('no-scroll');
    });

  });

  toggleSort.addEventListener('click', function() {
    this.classList.toggle('is-active');
    sort.classList.toggle('is-open');
  });

}

// HOMEPAGE SLIDER

const heroSliderSlides = document.querySelector('.hero-slider');
const heroSliderNav = document.querySelector('.hero-slider-nav');

if(heroSliderSlides) {
  const heroSlider = tns({
    container: heroSliderSlides,
    mode: 'carousel',
    items: 1,
    slideBy: 'page',
    autoplay: true,
    autoplayButtonOutput: false,
    speed: 500,
    controls: true,
    nav: true,
    mouseDrag: true,
    loop: true,
    controls: false,
    navContainer: heroSliderNav
  });
}

// FADE OUT COLLAB HERO

const collabHeroImgs = document.querySelector('.collab-hero-imgs');
const productHero = document.querySelector('.product-bg__img');

if (collabHeroImgs) {

  window.addEventListener('scroll', function() {
    // Get the scroll position
    var scrollPosition = window.scrollY;

    // Check if the scroll position is greater than a certain value (e.g., 100px)
    if (scrollPosition > 300) {
      // Add the 'has-faded' class to the box element
      collabHeroImgs.classList.add('has-faded');
    } else {
      // Remove the 'has-faded' class from the box element
      collabHeroImgs.classList.remove('has-faded');
    }
  });

}

// FADE ON PRODUCT HERO

if (productHero) {

  window.addEventListener('scroll', function() {
    // Get the scroll position
    var scrollPosition = window.scrollY;

    // Check if the scroll position is greater than a certain value (e.g., 100px)
    if (scrollPosition > 200) {
      // Add the 'has-faded' class to the box element
      productHero.classList.add('has-faded');
    } else {
      // Remove the 'has-faded' class from the box element
      productHero.classList.remove('has-faded');
    }
  });

}

// TIMED ANIMATIONS

const sticker = document.querySelector('.collab-product-img__sticker');
const preFooterCta = document.querySelector('.pre-footer-cta');

// Intersection Observer options
const options = {
  root: null, // Use the viewport as the root
  rootMargin: '0px', // No margin
  threshold: 0.5 // Trigger when 50% of the target is visible
};

// Callback function to handle intersection changes
const handleIntersection = (entries) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      // Add the class if the target is visible
      entry.target.classList.add('is-visible');
    } else {
      // Remove the class if the target is not visible
      entry.target.classList.remove('is-visible');
    }
  });
};

if(sticker) {
  const stickerObserver = new IntersectionObserver(handleIntersection, options);
  stickerObserver.observe(sticker);
}

if(preFooterCta) {
  const ctaObserver = new IntersectionObserver(handleIntersection, options);
  ctaObserver.observe(preFooterCta);
}

// SHOP FILTERING

const productFeed = document.querySelector('.product-feed--filtered');

if (productFeed) {
  const mixer = mixitup(productFeed, {
    animation: {
      enable: false,
      animateResizeContainer: false
    },
    load: {
      sort: 'name:asc'
  },
  });
}

// ALTERNATE TEXT IMG COLUMNS

// Get all div elements on the page
const textImgColumns = document.querySelectorAll('.text-img-outer');

// Loop through each div
for (let i = 0; i < textImgColumns.length; i++) {
    // Check if the index is even
    if (i % 2 === 1) { // Note: Index starts from 0, so odd index indicates even divs
        // Add the class 'is-even' to the div
        textImgColumns[i].classList.add('is-even');
    } else {
      textImgColumns[i].classList.add('is-odd');
    }
}

const wooMessage = document.querySelector('.woocommerce-message');

function hideWooCommerceMessage() {
  var messageDiv = document.querySelector('.woocommerce-message');
  if (messageDiv) {
      setTimeout(function() {
          messageDiv.style.display = 'none';
      }, 5000); // 5000 milliseconds = 5 seconds
  }
}

// Create an observer instance linked to the callback function
var observer = new MutationObserver(function(mutationsList, observer) {
  for (var mutation of mutationsList) {
      if (mutation.type === 'childList') {
          // Check if a new woocommerce-message div has been added
          mutation.addedNodes.forEach(function(node) {
              if (node.nodeType === 1 && node.classList.contains('woocommerce-message')) {
                  hideWooCommerceMessage();
              }
          });
      }
  }
});

// Start observing the document body for child nodes being added
observer.observe(document.body, { childList: true, subtree: true });

// END

});